import React, { useEffect, useState, useRef } from "react";
import "./Login.css";
import { Input, message } from "antd";
import { BASE_URL } from "../../utils/apiConfig";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const Login = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [resetPassword, setResetPassword] = useState("");
  const [confirmResetPassword, setConfirmResetPassword] = useState("");
  const otpRefs = useRef([]);

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const navigate = useNavigate();

  const PROD_BASE_URL = process.env.REACT_APP_PROD_BASE_URL;
  const STAGE_BASE_URL = process.env.REACT_APP_STAGE_BASE_URL;
  const NODE_ENV = process.env.NODE_ENV;

  console.log("REACT_APP_PROD_BASE_URL:", PROD_BASE_URL);
  console.log("REACT_APP_STAGE_BASE_URL:", STAGE_BASE_URL);
  console.log("NODE_ENV", NODE_ENV);
  console.log(BASE_URL, "baseurl");

  const handleLogin = () => {
    if (email && password) {
      if (password?.length < 6) {
        return message.error(
          "Password length should be longer than 6 characters"
        );
      }
      if (email.endsWith("@orchids.edu.in")) {
        const formData = new FormData();
        formData.append("contact", email);
        formData.append("password", password);
        axios
          .post("login/", formData)
          .then((res) => {
            // console.log(res);
            message.success("Logged In Successfully");
            navigate("/");
            window.location.reload();
            localStorage.setItem("userDetails", JSON.stringify(res?.data));
          })
          .catch((err) => {
            message.error(err?.response?.data?.message);
          });
      } else {
        message.error("Invalid email ID");
      }
    } else {
      message.error("Please enter both email and password.");
    }
  };

  const handleSendOtp = () => {
    if (resetEmail.endsWith("@orchids.edu.in")) {
      axios
        .get(`/user/otp-verification/?contact=${resetEmail}`)
        .then((res) => {
          message.success(
            "OTP sent successfully, Please check your Spam or Inbox for OTP"
          );
          setStep(3);
        })
        .catch((err) => {
          message.error("Failed to send OTP. Please try again.");
        });
    } else {
      message.error("Invalid email ID");
    }
  };

  const handleVerifyOtp = () => {
    const otpCode = otp.join("");
    axios
      .post(`/user/otp-verification/`, { contact: resetEmail, otp: otpCode })
      .then((res) => {
        message.success("OTP verified successfully");
        setStep(4);
      })
      .catch((err) => {
        message.error("Invalid OTP. Please try again.");
      });
  };

  const handleResetPassword = () => {
    if (resetPassword !== confirmResetPassword) {
      return message.error("Passwords do not match");
    }
    if (resetPassword?.length < 6) {
      return message.error(
        "Password length should be longer than 6 characters"
      );
    }
    const formData = new FormData();
    formData.append("contact", resetEmail);
    formData.append("password", resetPassword);
    axios
      .put("/user/reset-password/", formData)
      .then((res) => {
        message.success("Password reset successfully");
        setStep(1);
        handleClearFields();
      })
      .catch((err) => {
        message.error("Failed to reset password. Please try again.");
      });
  };

  const handleClearFields = () => {
    setEmail("");
    setPassword("");
    setResetEmail("");
    setOtp(Array(6).fill(""));
    setResetPassword("");
    setConfirmResetPassword("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && step === 1) {
      handleLogin();
    }
  };

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value !== "" && index < otp.length - 1) {
        otpRefs.current[index + 1].focus();
      }
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index]) {
      if (index > 0) {
        otpRefs.current[index - 1].focus();
      }
    }
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="login-container">
          <div className="login-box">
            {step === 1 ? (
              <>
                <h4 className="text-center">School's Universe Login</h4>
                <Input
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={handleEmailChange}
                  className="input-field"
                  onKeyPress={handleKeyPress}
                />
                <Input.Password
                  placeholder="Enter password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  className="input-field"
                  onKeyPress={handleKeyPress}
                />
                <button onClick={handleLogin} className="login-button">
                  Login
                </button>
                <button
                  onClick={() => setStep(2)}
                  className="forgot-password-button"
                >
                  Forgot Password?
                </button>
              </>
            ) : step === 2 ? (
              <>
                <h4>Forgot Password</h4>
                <Input
                  type="email"
                  placeholder="Enter your email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  className="input-field"
                />
                <button onClick={handleSendOtp} className="login-button">
                  Send OTP
                </button>
                <button onClick={() => setStep(1)} className="back-button">
                  Back to Login
                </button>
              </>
            ) : step === 3 ? (
              <>
                <h4>Verify OTP</h4>
                <div className="otp-container">
                  {otp.map((data, index) => (
                    <Input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={data}
                      onChange={(e) => handleOtpChange(e, index)}
                      onKeyDown={(e) => handleOtpKeyDown(e, index)}
                      ref={(el) => (otpRefs.current[index] = el)}
                      className="otp-input"
                    />
                  ))}
                </div>
                <button onClick={handleVerifyOtp} className="login-button">
                  Verify OTP
                </button>
                <button
                  onClick={() => {
                    setStep(2);
                    handleClearFields();
                  }}
                  className="back-button"
                >
                  Back
                </button>
              </>
            ) : (
              <>
                <h4>Reset Password</h4>
                <Input.Password
                  type="password"
                  placeholder="Enter new password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  value={resetPassword}
                  onChange={(e) => setResetPassword(e.target.value)}
                  className="input-field"
                />
                <Input.Password
                  type="password"
                  placeholder="Confirm new password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  value={confirmResetPassword}
                  onChange={(e) => setConfirmResetPassword(e.target.value)}
                  className="input-field"
                />
                <button onClick={handleResetPassword} className="login-button">
                  Submit
                </button>
                <button onClick={() => setStep(2)} className="back-button">
                  Back
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
